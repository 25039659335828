import React from 'react';
import classNames from 'classnames';
import { useSlotPlaceholder } from '@wix/widget-plugins-ooi';
import { BELOW_CONTENT_1, BELOW_CONTENT_2 } from '@app/constants/ooi-slots';
import { type LayoutConfigItemComponentProps } from '@app/external/feed-page/components/post-list/layout-config-component-props';
import PostPagePostCategories from './post-page-post-categories';
import PostPagePostContent from './post-page-post-content';
import PostPagePostFooterActions from './post-page-post-footer-actions';
import PostPagePostHeader from './post-page-post-header';
import PostPagePostTags from './post-page-post-tags';
import styles from './post-page-post.scss';

type Props = Pick<LayoutConfigItemComponentProps, 'post'> & {
  onRatingsDisplayClick: () => void;
};

const PostPagePost: React.FC<Props> = (props) => {
  const [BelowContent1SlotsPlaceholder, isBelowContent1SlotEmpty] =
    useSlotPlaceholder(BELOW_CONTENT_1);
  const [BelowContent2SlotsPlaceholder, isBelowContent2SlotEmpty] =
    useSlotPlaceholder(BELOW_CONTENT_2);
  const { post } = props;

  return (
    <article className={styles.root} data-hook="post">
      <header className={styles.contentSpacer}>
        <div className={styles.contentWrapper}>
          <PostPagePostHeader post={post} onRatingsDisplayClick={props.onRatingsDisplayClick} />
        </div>
      </header>
      <main className={styles.content} data-hook="post-description">
        <PostPagePostContent post={post} />
      </main>
      {!isBelowContent1SlotEmpty && (
        <section className={styles.belowContentSlot}>
          <BelowContent1SlotsPlaceholder />
        </section>
      )}
      {!isBelowContent2SlotEmpty && (
        <section className={styles.belowContentSlot}>
          <BelowContent2SlotsPlaceholder />
        </section>
      )}
      <footer className={classNames(styles.contentSpacer, styles.footer)} data-hook="post-footer">
        <div className={styles.contentWrapper}>
          <div className={classNames(styles.footerContentWrapper, styles.hideInPrint)}>
            <PostPagePostTags post={post} />

            <PostPagePostCategories post={post} />

            <PostPagePostFooterActions post={post} />
          </div>
        </div>
      </footer>
    </article>
  );
};

export default PostPagePost;
