import React from 'react';
import { Trans } from '@wix/yoshi-flow-editor';
import { getTPASettingsIsRatingsEnabled, resolveId } from '@wix/communities-blog-client-common';
import PostLink from '@app/external/common/components/link/post-link';
import { isPostUpdatedAfterFirstPublish } from '@app/external/common/components/post/utils';
import useSelector from '@app/external/common/components/runtime-context/use-selector';
import TimeAgo from '@app/external/common/components/time-ago';
import { getPostTotalRatings } from '@app/external/common/store/post-ratings/post-ratings-selector';
import { type NormalizedPost } from '@app/external/common/types';

import { isTextDirectionRTL } from '@app/external/rich-content/services/text-direction';
import { useFullPostLayoutOverrides } from './feed-overrides-context';
import PostPagePostHeaderMetadata from './post-page-post-header-metadata';
import PostPagePostHeaderMoreActions from './post-page-post-header-more-actions';
import PostPagePostHeaderRatings from './post-page-post-header-ratings';
import styles from './post-page-post-header.scss';

type Props = {
  post: NormalizedPost;
  onRatingsDisplayClick: () => void;
};

const PostPagePostHeader: React.FC<Props> = (props) => {
  const overrides = useFullPostLayoutOverrides();
  const isRatingsEnabledInTpaSettings = useSelector(getTPASettingsIsRatingsEnabled);

  const postTotalRatings = useSelector((state) =>
    getPostTotalRatings(state, resolveId(props.post)),
  );

  const showRatings = overrides.isInPostPage
    ? overrides.showPostRating && isRatingsEnabledInTpaSettings
    : overrides.showPostRating && isRatingsEnabledInTpaSettings && !!postTotalRatings;

  return (
    <>
      <div className={styles.postTitleContainer}>
        <div className={styles.titleWrapper} data-hook="post-title">
          <h1
            className={styles.title}
            data-hook="post-title"
            dir={
              typeof props.post.title === 'string' && isTextDirectionRTL(props.post.title)
                ? 'rtl'
                : undefined
            }
          >
            {overrides.isInPostPage ? (
              <>{props.post.title}</>
            ) : (
              <PostLink
                postLink={props.post.link}
                postPath={props.post.slug!}
                className={styles.titleLink}
              >
                {props.post.title}
              </PostLink>
            )}
          </h1>
        </div>
        <div className={styles.topWrapper}>
          <PostPagePostHeaderMetadata post={props.post} />
          <PostPagePostHeaderMoreActions post={props.post} />
        </div>
      </div>
      {overrides.showPostUpdatedDate && isPostUpdatedAfterFirstPublish(props.post) ? (
        <p className={styles.updateDate}>
          <Trans
            i18nKey="post.update-date-new"
            components={[<TimeAgo time={props.post.lastPublishedDate} />]}
          />
        </p>
      ) : null}
      {showRatings && (
        <PostPagePostHeaderRatings post={props.post} onClick={props.onRatingsDisplayClick} />
      )}
    </>
  );
};

export default PostPagePostHeader;
